/* eslint-disable no-invalid-this */
import React from "react";
import IconDubai from "./images/dubai.png";
import IconAjman from "./images/ajman.png";
import IconSharja from "./images/sharja.png";
import IconAbudhabi from "./images/abudhabi.png";
import styles from "./styles.css";
import { appUrl } from "../../../constants/url-constants";
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
const REGIONS_DATA = [
    { cityName: "Dubai", icon: IconDubai },
    { cityName: "Sharjah", icon: IconSharja },
    { cityName: "Abu-Dhabi", icon: IconAbudhabi },
    { cityName: "Ajman", icon: IconAjman }
];
const OurPresenceInRegions = () => {
    const baseUrl = "/buy-used-cars-";
    return (
        <div styleName={"styles.presenseBannerWrapper"}>
            <div>
                <article>
                    <h2>Our presence across 4 key regions in the UAE</h2>
                    <p>BROWSE BY LOCATION</p>
                </article>
            </div>
            <div styleName={"styles.rightGrid"}>
                {REGIONS_DATA.map(({ cityName, icon }, idx) => {
                    const url = `${appUrl()}${baseUrl}${dashedLowercase(cityName)}/`;
                    return (
                        <a key={idx} href={url}>
                            <img src={icon} alt="" width={76} height={76} loading="lazy"/>
                            <figcaption>{cityName}</figcaption>
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default OurPresenceInRegions;
